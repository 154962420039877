<template>
  <div>
    <ul class="pagination">
      <li v-if="selectedPage > 1" v-on:click="changePage(1)" style="border-left: none ;"><a>
        <font-awesome-icon icon="angle-double-left"  />
      </a></li>
      <li v-if="selectedPage > 1" v-on:click="changePage(selectedPage - 1)"><a>
        <font-awesome-icon icon="angle-left" />
        </a></li>
      <li :class="page  === selectedPage ? 'active': ''" v-for="page in pagesShown" v-bind:key="page" v-on:click="changePage(page)"><a >{{page}}</a></li>
      <li v-if="selectedPage < totalPages" v-on:click="changePage(selectedPage + 1)"><a>
        <font-awesome-icon icon="angle-right" />
        </a></li>
      <li v-if="selectedPage < totalPages" v-on:click="changePage(totalPages)"><a>
        <font-awesome-icon icon="angle-double-right" />
        </a></li>
    </ul>
  </div>
</template>
  
  <script>
  export default {
    name: 'PageTurner',
    data () {
      return {
        selectedPage: this.currentPage
      }
    },
    props: {
      pageSize: Number,
      pageTurnerSize: {
        type: Number,
        default: 10
      },
      totalItems: Number,
      currentPage: {
        type: Number,
        default: 1
      }
    },
    computed: {
      paginationStartFrom () {
        if (this.selectedPage < this.pageTurnerSize) {
          return 1
        }
        return this.selectedPage - (this.pageTurnerSize - 1)
      },
      paginationEnd () {
        if (this.paginationStartFrom + (this.pageTurnerSize - 1) < this.totalPages) {
          return this.paginationStartFrom + (this.pageTurnerSize - 1)
        }
        return this.totalPages
      },
      pagesShown () {
        const allPages = []
        let i = this.paginationStartFrom
        while (i <= this.paginationEnd) {
          allPages.push(i)
          i++
        }
        return allPages
      },
      totalPages () {
        const totalPages = Math.ceil(this.totalItems / this.pageSize)
        return (totalPages)
      }
    },
    methods: {
      changePage (number) {
        console.log('change page', number)
        if (number < 1 || number > this.totalPages) {
          return
        }
        this.selectedPage = number
        this.$emit('changePageEvent', number)
      }
    }
  }
  </script>
  <style lang = "scss" scoped>
  .pagination {
    padding-top: 10px;
    list-style-type: none;
    display: inline-block;
    font-weight: bolder;
  }
  .pagination li {
    float: left;
    //border: 1px solid darkgray;
    border-left: none;
    padding: 8px 16px;
    color: darkgray;
    text-decoration: none;
    transition: 0.3s;
  }
  .pagination li:first-child{
    border-left: 1px solid darkgray;
  }
  .pagination li.active {
    background-color: darkgray;
    color: white;
  }
  .pagination li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .pagination li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .pagination li:hover:not(.active) {
    background-color: darkgray;
  }
  .pagination li:hover {
    cursor: pointer;
  }
  </style>
  