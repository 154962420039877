<template>
  <v-app>
    <div class="center-float" v-if="!gitlabLoading">
      <div class="table-container">
        <div class="vcard">
          <v-card-title id="search-card">
            <span class="dark-text">
              L3 Tickets(GitLab) linked with {{ totalJiraLinked }} Jira Tickets
            </span>
            <v-select
              v-model="selectedMilestone"
              :items="milestones"
              label="Select Milestone"
              class="milestone-selector ew-tablesearch-input"
              @change="getGitlab(1)"
            ></v-select>
            <v-text-field
              v-model="textBox"
              prepend-inner-icon="mdi-magnify"
              label="Search in the table..."
              single-line
              hide-details
              class="ew-tablesearch-input"
              @keydown.enter="addChips"
            >
            </v-text-field>
          </v-card-title>
          <v-checkbox
            v-model="hideZeroLinkedTickets"
            label="Show only tickets with linked L2 tickets"
            class="zero-link-checkbox"
          ></v-checkbox>
        </div>
        <v-item-group v-show="chipData.length > 0" class="chips">
          <v-item> <span>Search Filters:</span></v-item>
          <v-item v-for="(chipText, index) in chipData" :key="index">
            <v-chip
              class="ma-1"
              close
              prepend-icon="mdi-checkbox-marked-circle"
              @click:close="removeChip(index)"
              >{{ chipText }}</v-chip
            >
          </v-item>
          <v-item>
            <v-btn
              depressed
              elevation="5"
              outlined
              raised
              rounded
              @click="clearChips"
              >Clear all</v-btn
            >
          </v-item>
        </v-item-group>
        <div v-if="chipData.length > 0">
          <div
            class="assigne-ticket-count"
            v-for="(labels, assignee) in uniqueAssignees"
            :key="assignee"
          >
            <h4>{{ assignee }} - &nbsp;</h4>
            <p>{{ formattedLabels(labels) }}</p>
          </div>
        </div>
        <Table
          :page-size="100"
          :columns="columns"
          :data="filteredData"
          :sortBy="sortBy"
        ></Table>
      </div>
      <div id="pageSelection" class="radio-toolbar">
        <PageTurner
          @changePageEvent="changePage"
          :pageSize="100"
          :totalItems="this.totalGitlabCount"
          :currentPage="selectedPage"
          :pageTurnerSize="20"
          v-if="totalGitlabCount > 100"
        ></PageTurner>
      </div>
    </div>
    <div class="loading-container" v-if="gitlabLoading">
      <div class="loading-spinner"></div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EventBus from "../../../utils/EventBus";
import Table from "../sharedcomponents/Table.vue";
import PageTurner from "../sharedcomponents/PageTurner.vue";

const EXCLUDED_LABELS = new Set([
  "request",
  "Workflow::ETA-pending-issue-accurate",
  "Workflow::ETA-assigned",
  "Workflow::Doing",
  "Workflow::Review Pending",
  "Workflow::Review Done",
  "Workflow::Dev-test Pending",
  "Workflow::Dev-Test Done",
  "Workflow:: L2 Pending",
  "Workflow::QE Pending",
  "Discussion/ Not-Clear",
  "on call backlog/ dependent",
  "Workflow:: Tested/Prod-Publish-Pending",
]);

const ALL_LABELS = ["Open"].concat(Array.from(EXCLUDED_LABELS));

export default {
  name: "Gitlab",
  components: {
    Table,
    PageTurner,
  },
  computed: {
    ...mapState(["gitlabList", "totalGitlabCount", "totalJiraLinked"]),
    filteredGitlabList() {
      return this.hideZeroLinkedTickets
        ? this.gitlabList.filter((item) => item.linkedTicketsCount > 0)
        : this.gitlabList;
    },
    filteredData() {
      const searchTerms = this.chipData.map((term) => term.toLowerCase());
      return this.filteredGitlabList.filter((item) => {
        return searchTerms.every((term) =>
          Object.values(item).some((val) =>
            val ? val.toString().toLowerCase().includes(term) : false
          )
        );
      });
    },
    uniqueAssignees() {
      if (this.chipData.length) {
        const searchTerms = this.chipData.map((term) => term.toLowerCase());
        return Object.fromEntries(
          Object.entries(this.assigneeMap).filter(([assignee]) =>
            searchTerms.some((term) => assignee.toLowerCase().includes(term))
          )
        );
      }
      return this.assigneeMap;
    },
  },

  data: () => ({
    columns: [
      {
        name: "Title",
        key: "title",
        sortable: false,
        clickable: false,
        visible: true,
      },
      {
        name: "Assignee",
        key: "assignee",
        sortable: false,
        clickable: false,
        visible: true,
      },
      {
        name: "Labels",
        key: "labels",
        sortable: false,
        clickable: false,
        visible: true,
      },
      {
        name: "Ticket ID",
        key: "number",
        sortable: false,
        clickable: true,
        width: "8%",
        visible: true,
      },
      {
        name: "Date Created",
        key: "createdDate",
        sortable: true,
        clickable: false,
        width: "8%",
      },
      {
        name: "Number of L2 Tickets Linked",
        key: "linkedTicketsCount",
        sortable: true,
        clickable: true,
        visible: true,
      },
      {
        name: "Severity",
        key: "severity",
        sortable: true,
        clickable: false,
        width: "6%",
        visible: true,
      },
      {
        name: "Date Updated",
        key: "updatedDate",
        sortable: true,
        clickable: false,
        width: "8%",
      },
      {
        name: "Due Date",
        key: "dueDate",
        sortable: true,
        clickable: false,
        width: "8%",
      },
    ],
    gitlabLoading: true,
    selectedPage: 1,
    textBox: "",
    chipData: [],
    sortBy: "linkedTicketsCount",
    hideZeroLinkedTickets: false,
    assigneeMap: {},
    milestones: ["On call", "Engineering Ready", "Grooming Pending"], // List of milestones
    selectedMilestone: "On call",
  }),

  beforeMount() {
    this.setDeploymentName(this.$route.query.platform);
  },

  async mounted() {
    await this.getGitlab(this.selectedPage);
    this.gitlabLoading = false;
    EventBus.$on("clickTableEvent", this.itemClicked);
    this.calculateAssigneeMap();
  },

  methods: {
    ...mapActions(["fetchGitlabList", "setDeploymentName"]),
    addChips() {
      if (this.textBox.length) {
        this.chipData.push(this.textBox);
        this.textBox = "";
      }
    },
    removeChip(index) {
      this.chipData.splice(index, 1);
    },
    clearChips() {
      this.chipData = [];
    },
    itemClicked(column, item) {
      if (column === "number") {
        window.open(item.url, "_blank");
      }
      if (column === "linkedTicketsCount") {
        window.open(`./ticketManagement/gitlab/${item.key}`, "_blank");
      }
    },
    async getGitlab(page) {
      try {
        await this.fetchGitlabList({
          data: { page, pageSize: 100, milestone: this.selectedMilestone },
          path: "/v1/gitlab/bulk",
        });
      } catch (error) {
        console.error("Failed to fetch GitLab tickets:", error);
      }
    },
    async changePage(number) {
      this.gitlabLoading = true;
      this.selectedPage = number;
      await this.getGitlab(this.selectedPage);
      this.calculateAssigneeMap();
      this.gitlabLoading = false;
    },
    calculateAssigneeMap() {
      const assigneeMap = {};
      this.gitlabList.forEach((item) => {
        this.processAssignees(item, assigneeMap);
      });
      this.assigneeMap = assigneeMap;
    },
    processAssignees(item, assigneeMap) {
      const assignees = item.assignee.split(",").map((name) => name.trim());
      const labels = item.labels.split(",").map((label) => label.trim());
      const isOpen = !labels.some((label) => EXCLUDED_LABELS.has(label));

      assignees.forEach((assignee) => {
        if (!assigneeMap[assignee]) {
          assigneeMap[assignee] = this.initializeLabelCount();
        }
        if (isOpen) {
          assigneeMap[assignee].Open++;
        } else {
          labels.forEach((label) => {
            if (ALL_LABELS.includes(label)) {
              assigneeMap[assignee][label]++;
            }
          });
        }
      });
    },
    initializeLabelCount() {
      const labelCount = {};
      ALL_LABELS.forEach((label) => {
        labelCount[label] = 0;
      });
      return labelCount;
    },
    formattedLabels(labels) {
      return Object.entries(labels)
        .filter(([_, count]) => count !== 0)
        .map(([label, count]) => `${label.replace("Workflow::", "")}: ${count}`)
        .join(", ");
    },
  },
};
</script>

<style scoped>
center-float {
  margin-top: 20px;
  margin-left: 250px;
}
#pageSelection {
  float: none;
  padding-top: 0.75rem;
}
.radio-toolbar div {
  display: flex;
  justify-content: center;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar span {
  float: left;
}

.radio-toolbar i {
  float: left;
  margin: 10px;
  align-self: center;
}

.radio-toolbar .page-label {
  font-size: 12px;
  padding: 5px;
}
.table-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
.ew-tablesearch-input {
  max-width: 250px !important;
}

#search-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.4rem;
}

.assigne-ticket-count {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1.4rem;
}

.zero-link-checkbox {
  padding: 0.5rem 1.4rem;
  margin-top: 0;
}

.chips {
  text-align: left;
  padding: 0.5rem 1.4rem;
}
.milestone-selector {
  padding-top: 2rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
